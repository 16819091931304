import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Crossfade = ({ ...props }) => {
  const [active, setActive] = useState(
    props.forceActiveImage ? parseInt(props.forceActiveImage) : 0
  );
  
  const [firstTransitionIsDone, setFirstTransitionIsDone] = useState(false);

  useEffect(() => {
    if (props.forceActiveImage) return;
    if (!props.cycle) return;
    const timeout = setTimeout(() => {
      const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
      async function startImageTransition() {
        if (firstTransitionIsDone) await wait(props.transition);
        setActive(active === props.images.length - 1 ? 0 : active + 1);
        setFirstTransitionIsDone(true);
      }
      if (props.cycle) {
        startImageTransition();
      }
    }, props.interval);

    return () => clearTimeout(timeout);
  }, [active, firstTransitionIsDone, props]);

  useEffect(() => {
    if (!props.forceActiveImage) return;
    setActive(parseInt(props.forceActiveImage));
  }, [active, props.forceActiveImage]);

  return (
    <StyledContainer {...props}>
      {props.images.map((image, index) => (
        <StyledImage
          key={`${image}-${index}`}
          style={{
            backgroundImage: `url(${image})`,
            opacity: active === index ? 1 : 0,
            transition: `opacity ${props.transition}ms`,
            ...props.imageStyles,
          }}
          {...props.imageProps}
        />
      ))}
    </StyledContainer>
  );
}

Crossfade.defaultProps = {
  forceActiveImage: null,
  cycle: true,
  interval: 5000,
  transition: 5000,
  images: [],
};

const StyledContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 700px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 425px) {
    width: 350px;
  }

  @media screen and (max-width: 350px) {
    width: 100%;
  }


`;

const StyledImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  background-size: cover;
  background-position: center;
`;


export default Crossfade;
